import './style.css';
import React, { useEffect, useState, useRef } from 'react';
import arror from './assets/arrow.png';
import blockscoutSearch from '../../api/blockscout-search';
import searchUri from '../../api/search-uri';
import isValidUrl from '../../common/isValidUrl';

const SearchAssistant = () => {
  const [prompt, setPrompt] = useState("");
  const [promptItems, setPromptItems] = useState([]);
  const [searchedItems, setSearchedItems] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const searchBoxRef = useRef(null); // Ref for the search box input element

  useEffect(() => {
    if (!!prompt.length) {
      if (prompt[prompt.length - 1] === " ") {
        setSearchedItems([]);
        return;
      }

      const words = prompt.split(" ");
      let q = words[words.length - 1];
      if (q.startsWith("@") && q.length > 1) {
        q = q.slice(1, q.length);
        blockscoutSearch(q)
          .then((result) => {
            setSearchedItems(result);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setSearchedItems([]);
    }
  }, [prompt]);

  const onClickSearch = (e) => {
    e.preventDefault();
    setSearchResult("");
    setSearchLoading(true);
    searchUri(prompt, promptItems, setSearchLoading)
      .then((result) => {
        setSearchResult(result);
        setSearchLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setSearchLoading(false);
      });
  };

  const onClickSearchItem = (index) => (e) => {
    e.preventDefault();
    const item = searchedItems[index];
    const words = prompt.split(" ");

    if (item.type === 'address' || item.type === 'contract' || item.type === 'token') {
      words[words.length - 1] = `@${item.name}`;
    } else if (item.type === 'transaction') {
      words[words.length - 1] = `@${item.tx_hash}`;
    } else if (item.type === 'block') {
      words[words.length - 1] = `@${item.block_number}`;
    }
    const newPrompt = words.join(" ") + " ";

    setPrompt(newPrompt);
    setSearchedItems([]);
    setPromptItems((pi) => [
      ...pi,
      {
        ...item,
        searchTerm: `@${item.name}`,
      },
    ]);

    // Programmatically focus on the search box after selecting an item
    searchBoxRef.current.focus();
  };

  return (
    <div>
      <div className="form">
        <input
          className="search-box"
          type="text"
          placeholder="AI search assistant for Base Goerli chain"
          onInput={(e) => {
            setPrompt(e.target.value);
          }}
          value={prompt}
          ref={searchBoxRef} // Assign the ref to the search box input element
        />
        <button className="search-button" onClick={(e) => onClickSearch(e)}>
          <img className="arrow" src={arror} alt="Logo" />
        </button>
      </div>
      <div className="dropdown">
        {prompt.length > 0 && searchedItems.length !== 0 && (
          <div className="dropdown-items">
            {searchedItems.map((item, index) => {
              if (index > 10) {
                return null;
              } else {
                return (
                  <div
                    className="item"
                    key={index}
                    onClick={onClickSearchItem(index)}
                  >
                    {(item.type === "token" ||  item.type === "contract" || item.type === "address") &&
                      <>
                        <div className="item-type" style={{backgroundColor: "#AFD3E2"}}>{item.type}</div>
                        <div className="item-name">{item.name}</div>
                        <div className="item-address">{item.address}</div>
                      </>
                    }
                    {item.type === "transaction" &&
                      <>
                        <div className="item-type" style={{backgroundColor: "#F9F871"}}>{item.type}</div>
                        <div className="item-address">{item.tx_hash}</div>
                      </>
                    }
                    {item.type === "block" &&
                      <>
                        <div className="item-type" style={{backgroundColor: "#DBCFFF"}}>{item.type}</div>
                        <div className="item-name">{item.block_number}</div>
                        <div className="item-address">{item.block_hash}</div>
                      </>
                    }
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
      {searchResult.length>0 && 
        <div className="search-result">
          {isValidUrl(searchResult) ?
            <>
              <b style={{color:"#122A87", margin: "5px"}}>Suggested URI:</b>
              <a href={searchResult} target='_blank' rel="noreferrer">{searchResult}</a>
            </>
            :
            <>{searchResult}</>
          }
        </div>
      }
      {searchLoading && <div className="search-result"><b style={{color:"#122A87"}}>Searching...</b></div>}
    </div>
  );
};

export default SearchAssistant;
