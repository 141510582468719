import axios from 'axios';

const searchUri = async (prompt, promptItems) => {
    const url = `${process.env.REACT_APP_BLOCKSCOUT_POC_BE_BASE_URL}/ai-assistant/suggest-uri`;

    // console.log(prompt);
    // console.log(promptItems);
    const words = prompt.split(" ");

    for (let i=0; i < words.length; i++) {
        
        if (words[i].startsWith("0x")) {
            if (words[i].length === 42) {
                words[i] = words[i] + " (address)";
            } else if (words[i].length === 66) {
                words[i] = words[i] + " (transaction hash)";
            }
        }
    }
    let formattedPrompt = words.join(" ") + " ";
    // console.log("1. ", formattedPrompt);


    for (let j=0; j < promptItems.length; j++) {
        if (promptItems[j].type === 'address' ) {
            formattedPrompt = formattedPrompt.replace(promptItems[j].searchTerm, promptItems[j].address+ ` (address)`);
        } else if (promptItems[j].type === 'contract') {
            formattedPrompt = formattedPrompt.replace(promptItems[j].searchTerm, promptItems[j].address+ ` (contract address)`);
        } else if (promptItems[j].type === 'token') {
            formattedPrompt =  formattedPrompt.replace(promptItems[j].searchTerm, promptItems[j].address+ ` (token address)`);
        } else if (promptItems[j].type === 'transaction') {
            formattedPrompt = formattedPrompt.replace(promptItems[j].searchTerm, promptItems[j].address+ ` (transaction hash)`);
        } else if (promptItems[j].type === 'block') {
            formattedPrompt = formattedPrompt.replace(promptItems[j].searchTerm, promptItems[j].address+ ` (block number)`);
        }
    }

    // console.log("2.", formattedPrompt);

    const body = {
        question: formattedPrompt
    }
    if (promptItems.length > 0) {
        body['type'] = promptItems[0].type;
        if (promptItems[0].type === 'address' || promptItems[0].type === 'contract' || promptItems[0].type === 'token') {
            body['value'] = promptItems[0].address;
        } else if (promptItems[0].type === 'transaction') {
            body['value'] = promptItems[0].tx_hash;
        } else if (promptItems[0].type === 'block') {
            body['value'] = promptItems[0].block_number;
        }
    }
    const response = await axios.post(url, body, {withCredentials: false})
    return response.data;
}

export default searchUri;

