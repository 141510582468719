import React, { useState, useRef, useEffect } from "react";
import arror from './assets/arrow.png';
import './style.css';
import explainContract from "../../api/explain-contract";
import ComingSoon from "./comingSoon";

const ContractAssistant = () => {
    // const [contractCode, setContractCode] = useState("");
    // const [loading, setLoading] = useState(false);
    // const [contractExplanation, setContractExplanation] = useState("");
    // const explainAreaRef = useRef(null);

    // useEffect(() => {
    //     scrollToBottom();
    // }, [contractExplanation]);

    // const scrollToBottom = () => {
    //     explainAreaRef.current.scrollTop = explainAreaRef.current.scrollHeight;
    // };

    // const onClickExplainButton = () => {
    //     setLoading(true);
    //     explainContract(contractCode, setContractExplanation)
    //         .then((result) => {
    //             setLoading(false);
    //             setContractExplanation(result);
    //         })
    //         .catch((e) => {
    //             setLoading(false);
    //             console.log(e);
    //         });
    // }

    return (
        // <div className="contract-body">
        //     <textarea 
        //         className="contract-area" 
        //         placeholder="Paste the web3 contract code that needs to be explained" 
        //         onChange={(e) => setContractCode(e.target.value)} 
        //     />
        //     <button className='explain-button' onClick={onClickExplainButton}>
        //         <img className='arrow' src={arror} alt="Logo" />
        //     </button>
        //     <textarea
        //         ref={explainAreaRef}
        //         className="contract-area"
        //         placeholder="The explanation of the contract will appear here"
        //         value={loading? "Loading...":contractExplanation}
        //         readOnly
        //     />
        // </div>
        <ComingSoon />
    )
}

export default ContractAssistant;
