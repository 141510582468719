import './App.css';
import React from 'react';
import SearchAssistant from './components/SearchAssistant';
import ContractAssistant from './components/ContractAssistant';

const App = () => {
  const [page, setPage] = React.useState(1)
  return (
    <div className="App">
      <div className="header">
        <div className="title">Blockscout AI POC</div>
        <div className="nav">
        <ul className="tab" onClick={() => setPage(1)} style={{color: page === 1 && "#122A87", backgroundColor: page === 1 && "#AFD3E2" }}>Search AI Assistant</ul>
        <ul className="tab" onClick={() => setPage(2)} style={{color: page === 2 && "#122A87", backgroundColor: page === 2 && "#AFD3E2" }}>Contract AI Assistant</ul>
      </div>
      </div>
      
      <div className="body">
        {page === 2 ? <ContractAssistant /> : <SearchAssistant />}
      </div>
    </div>
  );
}

export default App;
